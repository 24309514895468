exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-miners-json-name-armor-paint-jobs-tsx": () => import("./../../../src/pages/{MinersJson.name}/armor-paint-jobs.tsx" /* webpackChunkName: "component---src-pages-miners-json-name-armor-paint-jobs-tsx" */),
  "component---src-pages-miners-json-name-overclocks-tsx": () => import("./../../../src/pages/{MinersJson.name}/overclocks.tsx" /* webpackChunkName: "component---src-pages-miners-json-name-overclocks-tsx" */),
  "component---src-pages-miners-json-name-weapon-frameworks-tsx": () => import("./../../../src/pages/{MinersJson.name}/weapon-frameworks.tsx" /* webpackChunkName: "component---src-pages-miners-json-name-weapon-frameworks-tsx" */),
  "component---src-pages-miners-json-name-weapon-paint-jobs-tsx": () => import("./../../../src/pages/{MinersJson.name}/weapon-paint-jobs.tsx" /* webpackChunkName: "component---src-pages-miners-json-name-weapon-paint-jobs-tsx" */),
  "component---src-pages-pickaxe-tsx": () => import("./../../../src/pages/pickaxe.tsx" /* webpackChunkName: "component---src-pages-pickaxe-tsx" */)
}

